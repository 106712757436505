@font-face {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  src: url("./fonts/ProximaNova-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  src: url("./fonts/ProximaNova-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  src: url("./fonts/_ProximaNova-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  src: url("./fonts/ProximaNova-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

* {
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Define custom style for images */
.ql-image-style {
  max-width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
  /* Add other custom styles as needed */
}

iframe {
  background-color: #ffffff !important;
}

body {
  background-color: #ffffff !important;
}

img {
  pointer-events: none;
}

.menu-box {
  position: fixed;
  top: 64px; /* Adjust this based on the height of your AppBar */
  left: 0;
  right: 0;
  background: #000;
  z-index: 10;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0), 0 6px 6px rgba(0, 0, 0, 0.1);
  color: white;
}

#hubspotForm iframe {
  background-color: #000 !important;
}

/* footer form */
.footer-form {
  display: flex;
  column-gap: 8px;
}
