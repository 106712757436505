.select-container {
  position: relative;
  border-radius: 5px;
  border-radius: 4px;
  /* border: 1px solid #070f15; */
  color: #fff;
  cursor: pointer;
}

.select-container.disabled {
  cursor: not-allowed;
  --clr-primary: #4a98d3 !important;
  background: var(--clr-primary);
  opacity: 0.5;
}

.select-container-button {
  padding: 8px 16px;
  width: 100%;
  height: 100%;
  background-color: var(--clr-primary);
  text-align: left;
  margin: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-container-value {
  font-weight: 600;
}

.select-container-button p {
  margin: 0;
  padding: 0;
}

.select-container-button span {
  display: flex;
  align-items: center;
  gap: 5px;
}

.select-scrollbar {
  max-height: 250px;
  background-color: var(--clr-primary);
  position: absolute;
  transform: translateY(4px);
  width: 100%;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  z-index: 99;
}

.select-container-list {
  padding: 0;
  overflow: none;
  margin: 0;
  padding: 8px 16px;
  list-style: none;
  /* box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16); */
  color: #fff;
  border: none;
}

.select-container-item {
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  padding: 8px 0;
  letter-spacing: 0.005em;
}

.select-container-item:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.select-container .dropdown {
  width: 15px;
}

.select-container .loader {
  animation: spinner 1s infinite linear;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}
