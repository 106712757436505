.custom-tooltip {
    background: #DBEAF6;
    padding: 0 15px;
    border: 1px solid #4D4D4D;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.custom-tooltip-heading {
    color: #0D0D0D;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
}

.custom-tooltip-value {
    color: #666666;
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
}