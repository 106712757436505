.dropdown-container {
    text-align: left;
    /* border: 1px solid #ccc; */
    position: relative;
    border-radius: 5px;
    background-color: var(--clr-primary);
    border-radius: 4px;
    border: 1px solid #070F15;
    color: #fff;
}

.dropdown-container.disabled {
    cursor: not-allowed;
    background: var(--clr-primary);
    opacity: 0.5;
}

.dropdown-input {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.dropdown-menu {
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: none;
    max-height: 250px;
    /* background-color: #fff; */
    z-index: 99;
    margin: 0;
    padding: 8px 16px;
    list-style: none;
    background-color: var(--clr-primary);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    color: #fff;

}

.dropdown-item {
    cursor: pointer;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    padding: 8px 0;
    letter-spacing: 0.005em;
}

.dropdown-item:not(:last-child) {
    border-bottom: 1px solid #fff;
}

.dropdown-item:hover {
    background-color: var(--clr-primary);
}

.dropdown-item.selected {
    background-color: var(--clr-primary);
    color: #fff;
}

.dropdown-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.dropdown-tag-item {
    background-color: #ddd;
    padding: 2px 4px;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

.dropdown-tag-close {
    display: flex;
    align-items: center;
}

.search-box {
    padding: 5px;
    background-color: #eee;
}

.search-box input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.dropdown-selected-value {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-selected-value p {
    margin: 0;
    display: inline;
    /* font-weight: 400; */
}

.dropdown-tool {
    margin-top: 5px;
}

.dropdown-tool svg {
    height: auto;
    width: 15px;
    margin-left: 5px;
    fill: #fff;
}

.simplebar-scrollbar::before {
    background-color: #A4CBE9;
    opacity: 1;
}

.dropdown-selected-value-overview {
    font-weight: 700;
}