.select-container-1 {
    position: relative;
    border-radius: 5px;
    border-radius: 4px;
    border: 1px solid rgb(118, 118, 118);
    color: #000;
    font-size: 16px;
    font-weight: 275;

}

.select-container-1.disabled {
    cursor: not-allowed;
    --clr-primary: #4A98D3 !important;
    background: #fff;
    opacity: 0.5;
}

.select-container-1-button {
    padding: 8px 16px;
    width: 100%;
    height: 100%;
    background: none;
    text-align: left;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}




.select-container-1-button p {
    margin: 0;
    padding: 0;
}

.select-container-1-button span {
    display: flex;
    align-items: center;
    gap: 5px;
}


.select-scrollbar {
    max-height: 250px;
    background-color: var(--clr-primary);
    position: absolute;
    transform: translateY(4px);
    width: 100%;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    z-index: 99;
}

.select-container-1-list {
    padding: 0;
    overflow: none;
    margin: 0;
    padding: 8px 16px;
    list-style: none;
    background-color: #fff;
    color: #000;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    color: #fff;
    border: 1px solid rgb(118, 118, 118);
}

.select-container-1-item {
    cursor: pointer;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    padding: 8px 0;
    letter-spacing: 0.005em;
    color: #000;
}

.select-container-1-item:not(:last-child) {
    border-bottom: 1px solid rgb(118, 118, 118);
}

.select-container-1 svg {
    width: 15px;
}